const Logo = () => {
  return (
    <div className="logo fill-slate-100 text-slate-100 flex flex-col justify-center items-center">
      <svg x="0" y="0" width="150" height="150" viewBox="0 0 250 250" enableBackground="new 0 0 250 250">
        <g>
          <g
            style={{
              animation: "rotate 5s linear infinite",
              transformOrigin: "50% 50%",
            }}
          >
            <path d="M218.9,175c5.2,2.9,10.7,5.9,16.1,8.9c-23.3,46.2-83.3,80.6-147.1,60.5 c-66.6-21-92.7-85.1-87.1-130.1c6.2,0.3,12.4,0.6,18.7,0.8c-2.1,32.5,7.7,60.7,30.3,84c13.5,13.9,29.7,24.1,48.8,28.3 C151.1,238.9,190.2,219.2,218.9,175z" />

            <path d="M40.1,61.7c-4.8-3.6-9.8-7.4-14.8-11.2c26.2-37.8,83-63.1,139.4-43.9c60,20.5,88.6,77.8,85,125.3 c-6.2-0.6-12.4-1.2-18.2-1.7c-2.1-52.5-26.2-88.7-76.2-106.4C108.9,11.6,71.1,25.3,40.1,61.7z" />
          </g>

          <g
            style={{
              animation: "rotate-reverse 3.5s linear infinite",
              transformOrigin: "50% 50%",
            }}
          >
            <path d="M156.6,45.1c-2.4,5.4-4.8,10.8-7.3,16.3c25.6,9.8,43.8,34.6,43.8,63.6c0,26.9-15.6,50.1-38.2,61.2 c2.5,5.4,5.1,10.9,7.6,16.3c31.1-16.2,49.4-44.4,48.8-78.5C210.6,81.7,182.4,55,156.6,45.1z" />

            <path
              style={{ fill: "#e03848" }}
              d="M56.9,125.1c0-31.7,21.6-58.3,50.9-65.9c-1.5-5.8-2.9-11.5-4.3-17.4c-31.2,7.7-63.6,37.1-64.8,82.6 c-0.5,17.1,4.4,33.5,14.5,48c7.5,10.8,16.6,19.8,28,26.6c2.6-5,4.9-9.7,7.4-14.3c0.4-0.6,0.6-1.2,0.7-1.6 C69.8,171.1,56.9,149.6,56.9,125.1z"
            />
          </g>
          <rect className="slash" x="118" y="75" transform="matrix(0.9 0.44 -0.44 0.9 69.14 -43.1)" width="18" height="102.7" />
        </g>
      </svg>
      <div className="mt-8 text-3xl tracking-wider font-black z-10">CORS.TECH</div>
    </div>
  );
};

export default Logo;
