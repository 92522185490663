import Logo from "components/Logo";
import bg from "images/bg.jpg";

function App() {
  return (
    <div
      id="App"
      className="flex flex-col justify-center items-center min-h-screen bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${bg})`,
      }}
    >
      <div className="fixed inset-0 -z-0 bg-gray-800 bg-opacity-50"></div>
      <div className="z-10">
        <Logo />
      </div>
    </div>
  );
}

export default App;
